import { useQueryClient } from '@tanstack/react-query';

import { useApiEcomClientError } from '~/src/common/services/error-handler/error-handler';
import {
  getGetList2QueryKey,
  useAddProductToList,
  useGetList2,
  useRemoveArticleFromList,
} from '~/src/queries/api-ecom/generated/api-ecom';
import {
  GetList2200ProductsItem,
  GetList2200,
} from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import { ApiEcomError } from '~/src/queries/services/client';

import { DEFAULT_LIST_ID } from './constants';

const useUpdateBookmarkProduct = (canonicalId: string, isAuthenticated: boolean) => {
  const client = useQueryClient();
  const handleError = useApiEcomClientError();
  const { data: bookmarkList, refetch } = useGetList2(DEFAULT_LIST_ID, {
    query: { enabled: isAuthenticated },
  });

  const isBookmarked = bookmarkList?.products.some(product => product.canonicalId === canonicalId);

  const { mutate: addProductToList } = useAddProductToList({
    mutation: {
      onMutate: async ({ data }) => {
        await client.cancelQueries(getGetList2QueryKey(DEFAULT_LIST_ID));

        const cachedBookmarkList = client.getQueryData<GetList2200>(
          getGetList2QueryKey(DEFAULT_LIST_ID),
        );

        const newBookmarkProduct = { canonicalId: data.articleId };

        // Mise à jour optimiste
        if (cachedBookmarkList && bookmarkList) {
          // Split en deux listes produits disponibles et non disponibles
          const isProductAvailable = (p: GetList2200ProductsItem) =>
            p.enabled != null && p.availableQuantity > 0 && p.itemPrice != null;
          const available = cachedBookmarkList.products.filter(p => isProductAvailable(p));
          const unavailable = cachedBookmarkList.products.filter(p => !isProductAvailable(p));
          let newCachedProducts;

          // Inject en tête de la bonne liste (dispo/non dispo) le produit complet si possible sinon inject en-tête de la liste complète le produit avec son canonicalId uniquement
          const previousProduct = bookmarkList.products.find(
            p => p.canonicalId === newBookmarkProduct.canonicalId,
          );
          if (previousProduct != null) {
            const wasAddedProductAvailable = isProductAvailable(previousProduct);
            (wasAddedProductAvailable ? available : unavailable).unshift(previousProduct);
            newCachedProducts = [...available, ...unavailable];
          } else {
            newCachedProducts = [newBookmarkProduct, ...cachedBookmarkList.products];
          }

          // Puis update du cache
          client.setQueryData(getGetList2QueryKey(DEFAULT_LIST_ID), {
            ...cachedBookmarkList,
            products: newCachedProducts,
          });
        }
      },
      onError: (error: ApiEcomError) => {
        handleError(error);
      },
      onSettled: () => refetch(),
    },
  });

  const { mutate: removeProductFromList } = useRemoveArticleFromList({
    mutation: {
      onMutate: async ({ listId, articleId }) => {
        await client.cancelQueries(getGetList2QueryKey(listId));

        // Mise à jour optimiste
        if (bookmarkList) {
          client.setQueryData(getGetList2QueryKey(listId), {
            ...bookmarkList,
            products: bookmarkList.products.filter(product => product.canonicalId !== articleId),
          });
        }
      },
      onError: (error: ApiEcomError) => {
        handleError(error);
      },
      onSettled: () => refetch(),
    },
  });

  return { isBookmarked, addProductToList, removeProductFromList };
};

export default useUpdateBookmarkProduct;
