import { SyntheticEvent } from 'react';

import Icon from '~/src/common/components/Icon';
import { useAuthenticated } from '~/src/common/hooks/user/use-authenticated';
import I18n from '~/src/common/services/I18n';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import Toaster from '~/src/common/services/Toaster';
import Tracker, { BookmarkListProperties } from '~/src/common/services/Tracker';
import { BannerMessage } from '~/src/screens/AccountManagementModal';

import { DEFAULT_LIST_ID } from './constants';
import * as S from './layout';
import { Product } from './types';
import useUpdateBookmarkProduct from './use-update-bookmark-product';

export interface Props {
  product: Pick<Product, 'canonicalId'>;
  bookmarkEventProperties: BookmarkListProperties;
  notifyOnRemove?: boolean;
  className?: string;
}

const BookmarkProductButton = ({
  product,
  bookmarkEventProperties,
  notifyOnRemove = false,
  className,
}: Props) => {
  // Pour les produits destockés, on reçoit un canonicalId avec "DESTOCKING". On est obligé de supprimer cette partie de l'id pour pouvoir le comparer avec ceux les canonicalId des favoris qui n'ont pas "DESTOCKING"
  const canonicalId = product.canonicalId.replace(/DESTOCKING$/, '');

  const isAuthenticated = useAuthenticated();
  const accountManagementModal = useNiceModal('account-management-modal');
  const { isBookmarked, addProductToList, removeProductFromList } = useUpdateBookmarkProduct(
    canonicalId,
    isAuthenticated,
  );

  const handleCancelButtonClick = () => {
    Tracker.sendEvent('click on toaster to cancel deletion from favorites');

    addProductToList({
      listId: DEFAULT_LIST_ID,
      data: { articleId: canonicalId },
    });
  };

  const handleClick = (e?: SyntheticEvent) => {
    if (e != null) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (!isAuthenticated) {
      Tracker.sendEvent('click add to favorites list', {
        ...bookmarkEventProperties,
        'add or remove status': 'need login',
      });

      // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
      accountManagementModal.show({
        banner: { icon: 'heart-normal', message: BannerMessage.AddBookmark },
      });

      return;
    }

    if (isBookmarked) {
      removeProductFromList({
        listId: DEFAULT_LIST_ID,
        articleId: canonicalId,
      });

      if (notifyOnRemove) {
        Toaster.notify({
          icon: 'heart-normal',
          message: I18n.t('bookmark-product-button.bookmark-removed'),
          actionButtonLabel: I18n.t('common.cancel'),
          onActionButtonClick: handleCancelButtonClick,
        });
      }

      Tracker.sendEvent('click remove from favorites list', {
        ...bookmarkEventProperties,
        'add or remove status': 'product removed',
      });
    } else {
      addProductToList({
        listId: DEFAULT_LIST_ID,
        data: { articleId: canonicalId },
      });

      Tracker.sendEvent('click add to favorites list', {
        ...bookmarkEventProperties,
        'add or remove status': 'product added',
      });
    }
  };

  return (
    <S.BookmarkProductButton
      onClick={handleClick}
      className={className}
      aria-label={I18n.t(
        isBookmarked
          ? 'bookmark-product-button.remove-from-bookmark'
          : 'bookmark-product-button.add-to-bookmark',
      )}>
      <Icon
        name={isBookmarked ? 'heart-full' : 'heart-normal'}
        color={isBookmarked ? 'PITAYA' : 'PRIMARY'}
      />
    </S.BookmarkProductButton>
  );
};

export default BookmarkProductButton;
