import { useState, useEffect, useRef, Dispatch, useCallback } from 'react';

import { debounce } from 'debounce';

type UsePickerDebouncedPayload = {
  cachedQuantity: number;
  onUpdate: (quantity: number) => void;
  waitingDuration?: number;
};

const DEFAULT_DURATION = 500;

/**
 * Picker de produit connecté au cache de RQ
 * Quand on modifie la quantité interne on appelle la callback de mise a jour avec un debounce
 * Quand la quantité est modifiée depuis l'exterieur on n'appelle pas cette callback
 * @param cachedQuantity number : quantité extérieure (provenant du cache)
 * @param onUpdate Callback de mise a jour
 * @param waitingDuration number durée du debounce
 * @returns [internalCount, updateAndDebounce] l'état du compteur et sa fonction pour le mettre a jour
 */
export const usePickerDebounced = ({
  cachedQuantity,
  onUpdate,
  waitingDuration = DEFAULT_DURATION,
}: UsePickerDebouncedPayload): [quantity: number, setQuantity: Dispatch<number>] => {
  const [internalCount, setInternalCount] = useState(cachedQuantity);

  // Permet de tracker quand la modification est réalisée dans le cache de RQ
  const hasRequestedUpdateFromApi = useRef(true);

  // eslint-disable-next-line
  const updateDebounced = useCallback(
    debounce((count: number) => {
      onUpdate(count);
      hasRequestedUpdateFromApi.current = true;
    }, waitingDuration),
    [],
  );

  useEffect(() => {
    if (!hasRequestedUpdateFromApi.current) {
      updateDebounced(internalCount);
    }
  }, [internalCount, updateDebounced]);

  const updateAndDebounce = useCallback((quantity: number) => {
    hasRequestedUpdateFromApi.current = false;

    setInternalCount(quantity);
  }, []);

  // Dans le cas d'une mise à jour extérieur (par le modèle qui se met a jour depuis un autre picker)
  // On ne doit donc pas mettre a jour le cache de RQ
  useEffect(() => {
    setInternalCount(cachedQuantity);
  }, [cachedQuantity]);

  return [internalCount, updateAndDebounce];
};
