import { TippyProps } from '@tippyjs/react';
import dynamic from 'next/dynamic';

import { omit } from '~/src/common/utils/object';

const DynamicStyledTippy = dynamic(() => import('./Tooltip'), {
  ssr: false,
});

interface ConditionalTooltipProps extends TippyProps {
  disabled?: boolean;
}

const ConditionalTooltip = ({ disabled = false, children, ...props }: ConditionalTooltipProps) => {
  const p = omit(props, ['theme']);

  if (disabled) {
    return children ?? null;
  }

  return <DynamicStyledTippy {...p}>{children}</DynamicStyledTippy>;
};

export default ConditionalTooltip;
