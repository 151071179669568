import { Cart } from '~/src/common/typings/cart';
import { getCanonicalIdFromId } from '~/src/common/utils/product';
import { useGetCart } from '~/src/queries/api-ecom/generated/api-ecom';

export const useGetProductCartFreeArticleCoupons = (productId: string) => {
  const { data: cart } = useGetCart<Cart>();
  const coupons = (cart?.coupons ?? []).filter(({ usage }) => {
    if (usage.type !== 'FREE_ARTICLE') return false;
    return usage.article?.articleId === getCanonicalIdFromId(productId);
  });
  return coupons.reduce((sum, c) => sum + (c.usage.article?.quantity || 0), 0);
};
