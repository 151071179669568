import styled from 'styled-components';

export const BOOKMARK_PRODUCT_BUTTON_WIDTH = 24;

export const BookmarkProductButton = styled.button`
  width: ${BOOKMARK_PRODUCT_BUTTON_WIDTH}px;
  height: 24px;
  padding: 4.5px;

  border: none;
  background: none;

  cursor: pointer;

  > span {
    font-size: 15px;
  }
`;
